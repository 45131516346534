import React, { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './styles.css'; // Asegúrate de importar tus estilos personalizados
import { CartContext } from '../car/CartContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductListPrincipal = () => {
  const { productId } = useParams();
  const [subcategories, setSubcategories] = useState([]);
  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    axios.get(`https://api.smokegreeen.com/gpd/ad/getAleatory`)
      .then(response => {
        setSubcategories(response.data.data);
      })
      .catch(error => {
        console.error("Error fetching subcategories:", error);
      });
  }, [productId]);

  const handleAddToCart = (e, product) => {
    e.preventDefault();
    addToCart(product);
    toast.success('Producto agregado al carrito!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Lista de productos:</h1>
      <div className="subcategory-list">
        {subcategories.map(subcategory => (
          <div key={subcategory._id} className="subcategory-item-list">
            <Link to={`/detail/${subcategory._id}`}>
              <div className="container-card-listProduct">
                <img className="img-cart" src={subcategory.thumbnail} alt={subcategory.title} />
                <h4>{subcategory.title.length > 25 ? subcategory.title.slice(0, 25) + "..." : subcategory.title}</h4>
                <div className="properties">
                  <div className="precio" style={{ textAlign: "center" }}>
                    <div>
                      {subcategory.delivery ? (
                        <div className="delivery">Entrega: En stock</div>
                      ) :
                        <div className="delivery" style={{color:"red"}}>Entrega: Por Pedido</div>
                      }
                      {subcategory.discount ? (
                        <div className="discount-porperties">
                          <div className="discount">-{subcategory.discountPorcent}% Promocion</div>
                          <p className="priceBeforeTxt">Precio anterior</p>
                          <p className="priceBefore">${subcategory.price}</p>
                        </div>
                      ) : null}
                    </div>
                    {!subcategory.discount ? (
                      <h4>Total Precio</h4>) : null}
                    <span className="price-inr">
                      {subcategory.discount ? (
                        <span className="amount">$ {subcategory.priceDiscount}</span>) :
                        <span className="amount">$ {subcategory.price}</span>
                      }
                    </span>
                  </div>
                </div>
                <div className="add-to-cart-container">
                  <button
                    className="ip-add-cart-inr"
                    onClick={(e) => handleAddToCart(e, subcategory)}
                  >
                    <FontAwesomeIcon icon={faWhatsapp} style={{ color: 'white', marginRight: '10px' }} />
                    Agregar al carrito
                  </button>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProductListPrincipal;
