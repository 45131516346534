import React, { useState, useEffect } from 'react';
import Mapa from '../../components/maps/Mapa';
import datosGeoJSON from '../../components/maps/datosGeoJSON'; // Importa los datos del archivo
import VehicleList from '../../components/vehicle/vehicleList'; // Importa los datos del archivo
import VehicleCard from '../../components/vehicle/vehicleCard'; // Asegúrate de importar correctamente el componente
import SliderComponent from '../../components/sliders/SliderComponent';
import CustomArrows from '../../components/sliders/CustomArrows'; // Importa los datos del archivo
import TwoColumnsComponent from '../../components/twoColumns/TwoColumnsComponent'; // Importa los datos del archivo
import SliderBannerComponent from '../../components/banners/sliderBanner';
import BannerDinamicComponent from '../../components/banners/bannerDinamic';
import ProductListPrincipal from '../../components/principalList/listPrincipal';


function Home() {
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedListVehicle, setSelectedListVehicle] = useState(null);

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleVehicleSelection = (vehicle) => {
        // Establece el vehículo seleccionado en el estado
        setSelectedVehicle(vehicle);
    };

    const handleListVehicleSelection = (vehicle) => {
        setSelectedListVehicle(vehicle)
    };

    const handleVehicleDelete = () => {
        // Limpia selectedVehicle
        setSelectedVehicle(null);
    };

    return (

        <div>
            <BannerDinamicComponent
                text="Protege Nuestro Hogar 2"
                imageSrc="https://api.smokegreeen.com/gpd/images/bucket/?bukcket=uploads/2024/6/1/1/_2024_6_5605b3db72d5b_a.png"
            />
            <CustomArrows />
            <TwoColumnsComponent />
            <ProductListPrincipal />
            
        </div>
    );
}

export default Home;
