// components/floatingButton/FloatingButton.js
import React from 'react';
import './floatingButton.css';
import { useNavigate } from 'react-router-dom';

const FloatingButton = ({ isVisible }) => {
  const navigate = useNavigate();

  return (
    <button
    className={`floating-button ${isVisible ? 'visible' : 'hidden'}`}
    onClick={() => navigate('/cart')}
    >
      Ver Carrito
    </button>
  );
};

export default FloatingButton;
