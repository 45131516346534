import React, { Component } from "react";
import Slider from "react-slick";
import axios from "axios";
import './stylesCusto.css'; // Asegúrate de importar tus estilos personalizados
import { Link } from "react-router-dom"; // Importa Link de react-router-dom

class CustomArrows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [] // Inicializa products como un arreglo vacío
    };
  }

  componentDidMount() {
    axios.get("https://api.smokegreeen.com/gpd/catalog/getCategorys/v")
      .then(response => {
        // Almacena los datos del servicio en el estado
        this.setState({ products: response.data.data });
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }

  render() {
    const { products } = this.state;

    // Verifica si products no es un arreglo
    if (!Array.isArray(products)) {
      return <div>No hay productos disponibles</div>;
    }

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024, // Se ajustará en pantallas de 1024px o menos
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768, // Se ajustará en pantallas de 768px o menos
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 480, // Se ajustará en pantallas de 480px o menos
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };
    

    return (

      <div className="slider-container">
        <div className="titleCategory" >CATEGORIAS</div>
        <Slider {...settings}>
          {products.map(product => (
            <div key={product.id} className="product-item">
              <Link to={`/categoryList/${product.id}`}>

                <img
                  className="card-img-top img-first"
                  src={product.thumbnail}
                  alt="Product Image"
                  style={{ height: "230px" }}
                />
                <div className="card-body">
                  <h5 className="card-title" style={{ textAlign: 'center' }}>
                    {product.name.length > 20 ? product.name.slice(0, 20) + "..." : product.name}
                  </h5>
                </div>
              </Link>
            </div>

          ))}
        </Slider>
      </div>
    );
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "green" }}
      onClick={onClick}
    />
  );
}

export default CustomArrows;
