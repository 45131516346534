import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../car/CartContext'; // Importa el contexto del carrito
import './styles.css'; // Asegúrate de importar tus estilos personalizados
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faHome } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const MenuBar = () => {
  const { cart } = useContext(CartContext); // Obtén el carrito desde el contexto

  const whatsappNumber = '527225183273';
  const messageIntro = 'Buen día, me interesan ponerme en contacto con ustedes:';
  const encodedMessageIntro = encodeURIComponent(messageIntro);
  const whatsappUrl = `https://api.whatsapp.com/send/?phone=${whatsappNumber}&text=${encodedMessageIntro}&type=phone_number&app_absent=0`;

  return (
    <div className="nav-wrapper">
      <div className="container">
        <nav className="navbar">
          <div className="left-section">
            <Link to="/" className="navbar-link">
              <p>
                SMOKEGREEN
              </p>
            </Link>
          </div>
          <div className="right-section">
            <ul className="navbar-list">
              <li className="navbar-item">
                <Link to="/" className="navbar-link">
                  <FontAwesomeIcon icon={faHome} className="navbar-tool-icon czi-cart" />
                </Link>
              </li>
              <li className="navbar-item">
                {/* Integración del código HTML proporcionado */}
                <Link to="/cart" className="navbar-tool-icon-box bg-secondary dropdown-toggle">
                  <span className="navbar-tool-label">{cart.length}</span>
                  <FontAwesomeIcon icon={faShoppingCart} className="navbar-tool-icon czi-cart" style={{ color: "black" }} />
                </Link>
              </li>
              <li className="navbar-item">
                <a href={whatsappUrl} className="navbar-link" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faWhatsapp} style={{ color: 'black', marginRight: '10px' }} />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MenuBar;
