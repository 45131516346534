import React, { useContext } from 'react';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { CartContext } from '../car/CartContext'; // Importa el contexto del carrito
import { nanoid } from 'nanoid'; // Asegúrate de tener esto instalado: npm install nanoid

const FinishOrderButton = () => {
  const { cart } = useContext(CartContext); // Obtén el carrito desde el contexto

  const handleOrderFinish = () => {
    // Definir la URL a la que se enviará la solicitud POST
    const url = 'https://api.smokegreeen.com/gpd/ad/sendOrderWeb';

    // Crear el objeto de solicitud
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ products: cart }) // Colocar el contenido del carrito en el cuerpo de la solicitud
    };

    // Enviar la solicitud
    fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al enviar la solicitud');
        }
        return response.json();
      })
      .then(data => {
        console.log('Solicitud enviada con éxito:', data);
        // Aquí puedes manejar la respuesta del servidor si es necesario

        // Generar el mensaje de WhatsApp
        const phoneNumber = '7225183273'; // Número de teléfono al que se enviará el mensaje
        const messageIntro = 'Buen día, me interesan estos productos:\n\n';
        const messageProducts = cart.map(item => 
          `- ${item.title} $${item.discount ? item.priceDiscount : item.price} = $${item.discount ? item.priceDiscount : item.price}`
        ).join('\n');
        
        const total = cart.reduce((acc, item) => 
          acc + (item.discount ? item.priceDiscount : item.price), 0
        );
        console.log('total----',total)
                const messageTotal = `\n\n*Total:* $${total}\n\n`;
        const orderCode = `Código de pedido: ${nanoid(4)}`; // Genera un código de pedido único
        const message = encodeURIComponent(messageIntro + messageProducts + messageTotal + orderCode );
        const whatsappUrl = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${message}&type=phone_number&app_absent=0`;
        // Redirigir al usuario a la URL de WhatsApp
        window.location.href = whatsappUrl;
      })
      .catch(error => {
        console.error('Error al enviar la solicitud:', error);
        // Aquí puedes manejar los errores de la solicitud si es necesario
      });
  };

  return (
    <Fab
      variant="extended"
      size="large"
      color="success"
      onClick={handleOrderFinish}
      style={{ backgroundColor: '#51AC4F', color: 'white' }}
    >
      <WhatsAppIcon sx={{ mr: 1 }} />
      Finalizar pedido en WhatsApp ({cart.length} productos)
    </Fab>
  );
};

export default FinishOrderButton;
