import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import './styles.css'; // Asegúrate de importar tus estilos personalizados

const ProductList = () => {
  const { productId } = useParams();
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    axios.get(`https://api.smokegreeen.com/gpd/catalog/getCategorys/${productId}`)
      .then(response => {
        setSubcategories(response.data.data);
      })
      .catch(error => {
        console.error("Error fetching subcategories:", error);
      });
  }, [productId]);

  return (
    <div>
      <h1 style={{textAlign:'center'}}>Lista de productosR</h1>
      <div className="subcategory-list">
        {subcategories.map(subcategory => (
          <div key={subcategory.id} className="subcategory-item">
            <Link to={`/detail/${subcategory.id}`}>
              <img
                className="subcategory-thumbnail"
                src={subcategory.thumbnail}
                alt="Product Image"
              />
              <div className="subcategory-name">
                {subcategory.name.length > 20 ? subcategory.name.slice(0, 20) + "..." : subcategory.name}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
