import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importa los estilos de AOS
import './bannerDinamic.css'; // Importa tus estilos personalizados
import Typed from 'react-typed';

const BannerDinamicComponent = ({ text, imageSrc, showBanner }) => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Duración de la animación en milisegundos
    });
  }, []);

  const handleClick = () => {
    const phoneNumber = '7225183273'; // Número de teléfono al que se enviará el mensaje
    const messageIntro = 'Buen día, me interesan la promocion 1\n\n';
    const messageProducts = ''; // Puedes agregar detalles de los productos aquí
    const total = 0; // Puedes calcular el total aquí si es necesario
    const message = encodeURIComponent(messageIntro + messageProducts);
    const whatsappUrl = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${message}&type=phone_number&app_absent=0`;

    // Redirigir al usuario a la URL de WhatsApp     <div className="widget-banner updowDinamic" onClick={handleClick}>

    window.location.href = whatsappUrl;
  };

  return (
    <div className="widget-banner updowDinamic" >
      <div className="inner">
        <div className="col-xs-12">
          <div className="banner-image">
            <div className="image-wrapper image-loaded">
              <img
                loading="lazy"
                decoding="async"
                width="470"
                height="270"
                src={imageSrc}
                className="attachment-full size-full unveil-image"
                alt="Banner Image"
                srcSet={`${imageSrc} 470w, ${imageSrc} 300w`}
                sizes="(max-width: 470px) 100vw, 470px"
              />
            </div>
            {false && (
              <div className="banner-content style2">
                <div className="text-content" data-aos="fade-up">
                  <h2 className="animated-text-din">
                    <Typed
                      strings={[text]}
                      typeSpeed={50} // Velocidad de la animación
                    />
                  </h2>
                  <h5>Sobre pedido</h5>
                  <div className="link-bottom">
                    <span className="btn radius-50 btn-theme">Cotiza hoy</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerDinamicComponent;
