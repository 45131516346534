
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import './styles.css'; // Asegúrate de importar tus estilos personalizados

const CategoryList = () => {
  const { productId } = useParams();
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    console.log('----ROOOO---')
    axios.get(`https://api.smokegreeen.com/gpd/catalog/getCategorys/${productId}`)
      .then(response => {
        setSubcategories(response.data.data);
      })
      .catch(error => {
        console.error("Error fetching subcategories:", error);
      });
  }, [productId]);

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Sub - categorías:</h1>
        <div className="subcategory-list-lv2">
          {subcategories.map(subcategory => (
            <div key={subcategory.id} className="subcategory-item-list">
              <Link to={`/productList/${subcategory.id}`}>
                <body>
                  <div class="container-card">
                    <img class="img-cart" src={subcategory.thumbnail} />
                    <h4>{subcategory.name.length > 25 ? subcategory.name.slice(0, 25) + "..." : subcategory.name}</h4>
                  </div>
                </body>
              </Link>
            </div>
          ))}
        </div>
    </div>
  );
};

export default CategoryList;

