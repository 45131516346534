import React, { useEffect } from 'react';
import AOS from 'aos';
import './styles.css'; // Importa los estilos personalizados
import { useParams, Link } from "react-router-dom";

const BannerComponent = ({ text, imageSrc, showBanner ,txtH3,txtH5,txtBtn,promotion,redirectId}) => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Duración de la animación en milisegundos
    });
  }, []);

  const handleClick = () => {
    const phoneNumber = '7225183273'; // Número de teléfono al que se enviará el mensaje
    const messageIntro = `Buen día, me interesan la promoción ${promotion} \n\n`;
    const messageProducts = ''; // Puedes agregar detalles de los productos aquí
    const total = 0; // Puedes calcular el total aquí si es necesario
    const message = encodeURIComponent(messageIntro + messageProducts);
    const whatsappUrl = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${message}&type=phone_number&app_absent=0`;

    // Redirigir al usuario a la URL de WhatsApp
    window.location.href = whatsappUrl;
  };

  return (
    <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-df79eaa" data-id="df79eaa" data-element_type="column">
      <div className="elementor-widget-wrap elementor-element-populated">
        <div className="elementor-element elementor-element-da239bb elementor-widget elementor-widget-hyori_banner" data-id="da239bb" data-element_type="widget" data-widget_type="hyori_banner.default">
          <div className="elementor-widget-container">
            <div className="widget-banner updow">
              <div className="inner">
                <div className="col-xs-12">
                <Link to={`/productList/${redirectId}`}>

                  <div className="banner-image" style={{ cursor: 'pointer' }}>
                    <div className="image-wrapper image-loaded">
                      <img
                        loading="lazy"
                        decoding="async"
                        width="470"
                        height="270"
                        src={imageSrc}
                        className="attachment-full size-full unveil-image"
                        alt=""
                        srcSet={`${imageSrc} 470w, ${imageSrc} 300w`}
                        sizes="(max-width: 470px) 100vw, 470px"
                      />
                    </div>
                    {showBanner && (
                      <div className="banner-contentx">
                        <div className="banner-content-innerx">
                          <h3>{txtH3}</h3>
                          <h5>{txtH5}</h5>
                          <div className="link-bottom">
                            <span className="btn radius-50 btn-theme">{txtBtn}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerComponent;
