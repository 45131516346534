import React, { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { getProductDetail } from './productService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { CartContext } from '../car/CartContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import { Helmet } from 'react-helmet';

const Detail = () => {
  const { productId } = useParams();
  const [backgroundPosition, setBackgroundPosition] = useState('0% 0%');
  const [productDetail, setProductDetail] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addToCart } = useContext(CartContext);

  const capitalizeText = (text) => {
    if (!text) return "";
    return text.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  };

  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        const data = await getProductDetail(productId);
        if (!data[0]._id) {
          throw new Error("El producto no tiene un ID.");
        }
        if (!data[0].description) {
          throw new Error("El producto no tiene una descripción.");
        }

        // Transformar título y descripción
        data[0].title = capitalizeText(data[0].title);
        data[0].description = capitalizeText(data[0].description);

        setProductDetail(data[0]);

        // Track product view event
        if (window.fbq) {
          window.fbq('track', 'ViewContent', {
            content_ids: [data[0]._id],
            content_name: data[0].title,
            content_type: 'product',
            value: data[0].price,
            currency: 'MXN'
          });
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetail();
  }, [productId]);

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setBackgroundPosition(`${x}% ${y}%`);
  };

  const handleAddToCart = (e) => {
    e.preventDefault();
    addToCart(productDetail);
    toast.success('Producto agregado al carrito!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // Track add to cart event
    if (window.fbq) {
      window.fbq('track', 'AddToCart', {
        content_ids: [productDetail._id],
        content_name: productDetail.title,
        content_type: 'product',
        value: productDetail.price,
        currency: 'MXN'
      });
    }
  };

  if (loading) {
    return <div className="loader">Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="detail-container">
      <Helmet>
        <meta property="og:title" content={productDetail.title} />
        <meta property="og:description" content={productDetail.description} />
        <meta property="og:url" content={`https://smokegreeen.com/detail/${productId}`} />
        <meta property="og:image" content={productDetail.thumbnail} />
        <meta property="product:brand" content={productDetail.brand || "Default Brand"} />
        <meta property="product:availability" content="in stock" />
        <meta property="product:condition" content="new" />
        <meta property="product:price:amount" content={productDetail.price} />
        <meta property="product:price:currency" content="MXN" />
        <meta property="product:retailer_item_id" content={productDetail._id} />
        <meta property="product:item_group_id" content="fb_tshirts" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "productID": productDetail._id,
            "name": productDetail.title,
            "description": productDetail.description,
            "url": `https://smokegreeen.com/detail/${productId}`,
            "image": productDetail.thumbnail,
            "brand": productDetail.brand || "Default Brand",
            "offers": [
              {
                "@type": "Offer",
                "price": productDetail.price,
                "priceCurrency": "MXN",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock"
              }
            ],
            "additionalProperty": [
              {
                "@type": "PropertyValue",
                "propertyID": "item_group_id",
                "value": "fb_tshirts"
              }
            ]
          })}
        </script>
      </Helmet>
      <div
        className="image-wrapper-detail"
        onMouseMove={handleMouseMove}
      >
        <img
          src={productDetail.thumbnail}
          alt="Zoomable"
          className="zoomable-image"
        />
      </div>
      <div className="description">
        <h2 style={{ textAlign: 'center' }}>{productDetail.title}</h2>
        <p>{productDetail.description}</p>
        {productDetail.discount ? (
          <p>Precio: ${productDetail.priceDiscount}</p>) : <p>Precio: ${productDetail.price}</p>}        <div className="add-to-cart-container-detail">
          <button
            className="ip-add-cart-inr"
            onClick={handleAddToCart}
          >
            <FontAwesomeIcon icon={faWhatsapp} style={{ color: 'white', marginRight: '10px' }} />
            Agregar al carrito
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Detail;
